/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
/* Classi sui colori */
.ion-color-white {
  --ion-color-base: var(--ion-color-white) !important;
  --ion-color-base-rgb: var(--ion-color-white-rgb) !important;
  --ion-color-contrast: var(--ion-color-white-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-white-shade) !important;
  --ion-color-tint: var(--ion-color-white-tint) !important;
}

.ion-color-yellow {
  --ion-color-base: var(--ion-color-warning) !important;
  --ion-color-base-rgb: var(--ion-color-yellow-rgb) !important;
  --ion-color-contrast: var(--ion-color-yellow-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-yellow-shade) !important;
  --ion-color-tint: var(--ion-color-yellow-tint) !important;
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange) !important;
  --ion-color-base-rgb: var(--ion-color-orange-rgb) !important;
  --ion-color-contrast: var(--ion-color-orange-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-orange-shade) !important;
  --ion-color-tint: var(--ion-color-orange-tint) !important;
}

/* Classi su dimensioni */
.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-direction-col {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

.full-h {
  height: 100vh;
}

.full-h-percent {
  height: 100%;
}

.full-w {
  width: 100vh;
}

.full-w-percent {
  width: 100%;
}

.vmax-20-w {
  width: 20vmax;
}

.vmax-20-h {
  height: 20vmax;
}

.cursor-pointer {
  cursor: pointer;
}

.vmax-20-w {
  width: 20vmax;
}

.no-border-toolbar {
  --border-width: 0 !important;
}

.border-radius-10px {
  border-radius: 10px;
}

ion-label {
  white-space: normal !important;
  font-size: 18px !important;
}

.loader {
  --min-height: 100vh;
  --min-width: 100vw;
  opacity: 0.5;
  --backdrop-opacity: 0.5;
  --spinner-color: var(--ion-color-primary);
}
.loader ion-spinner {
  transform: scale(5);
}

.generic-modal, .termini-condizioni-modal {
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;
  --ion-background-color: var(--ion-color-white) !important;
  --ion-toolbar-background: var(--ion-color-white) !important;
  --ion-toolbar-border-color: var(--ion-color-white) !important;
  --width: 40%;
  color: var(--ion-color-white-contrast) !important;
  --height: auto;
}
.generic-modal.bottom, .bottom.termini-condizioni-modal {
  align-items: flex-end;
}
.generic-modal .ion-page, .termini-condizioni-modal .ion-page {
  position: relative;
  display: block;
  contain: content;
}
.generic-modal .ion-page .inner-content, .termini-condizioni-modal .ion-page .inner-content {
  max-height: 95vh;
  overflow-y: auto;
}

@media all and (max-device-width: 1280px) {
  .generic-modal, .termini-condizioni-modal {
    --width: 60%;
  }
}
@media all and (max-device-width: 1024px) {
  .generic-modal, .termini-condizioni-modal {
    --width: 80%;
  }
}
.termini-condizioni-modal {
  --width: 90%;
}

.primary-accordion {
  background-color: var(--ion-color-primary) !important;
}

.secondary-accordion {
  color: var(--ion-color-white);
}

.tertiary-accordion {
  background-color: var(--ion-color-tertiary) !important;
}

/*ion-toggle {
  padding: 12px;

  --track-background: #ddd !important;
  --track-background-checked: #ddd !important;
  --background: #ddd !important;
  --background-checked: #99edc3 !important;
  --handle-background-checked: rgb(84,184,45) !important;
}*/
ion-toggle::part(track) {
  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
}

.header-title {
  padding-inline: 0 !important;
  font-size: initial;
}

@media all and (max-device-width: 300px) {
  .header-title {
    font-size: 12px;
  }
}
.custom-selectable {
  --border-radius: 6px;
}
.custom-selectable ion-label {
  white-space: normal !important;
}

ionic-selectable-modal ion-label {
  white-space: normal !important;
}
ionic-selectable-modal ion-title {
  white-space: normal !important;
}
ionic-selectable-modal ion-title span {
  white-space: normal !important;
}
ionic-selectable-modal ion-title::part(toolbar-title) {
  white-space: normal !important;
}
ionic-selectable-modal ion-toolbar {
  white-space: normal !important;
}
ionic-selectable-modal .toolbar-title {
  white-space: normal !important;
}

.ionic-selectable-value-item {
  white-space: normal !important;
  font-size: 18px !important;
}

@media all and (max-device-width: 760px) {
  .custom-selectable {
    --height: 50% !important;
    --width: 80% !important;
  }
}
@media all and (max-device-width: 300px) {
  ionic-selectable-modal ion-toolbar {
    padding-inline: 0 !important;
  }
  ionic-selectable-modal ion-title span {
    font-size: 12px !important;
  }
}
ol {
  padding-left: 1em;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-primary);
}

::-webkit-scrollbar {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--ion-color-primary);
  border-radius: 10px;
  height: 1px;
}

.splash-fill {
  object-fit: fill;
}

.crop-center-img {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.second-toolbar {
  height: 44px !important;
  padding-top: 0 !important;
}

.div-score-result {
  width: 40%;
}