/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #035397;
  --ion-color-primary-rgb: 3, 83, 151;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: #E63137;
  --ion-color-secondary-rgb: 230, 49, 55;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #E63137;
  --ion-color-secondary-tint: #E63137;
  /** tertiary **/
  --ion-color-tertiary: #333333;
  --ion-color-tertiary-rgb: 51, 51, 51;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #333333;
  --ion-color-tertiary-tint: #333333;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #ffffff;
  --ion-color-light-tint: #ffffff;
  /** white**/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;
  /** yellow **/
  --ion-color-yellow: #ffc409;
  --ion-color-yellow-rgb: 255, 196, 9;
  --ion-color-yellow-contrast: #ffffff;
  --ion-color-yellow-contrast-rgb: 255, 255, 255;
  --ion-color-yellow-shade: #e0ac08;
  --ion-color-yellow-tint: #ffca22;
  /** orange **/
  --ion-color-orange: #ff4d00;
  --ion-color-orange-rgb: 255,77,0;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #e0ac08;
  --ion-color-orange-tint: #ff4d00;
  /** grey **/
  --ion-color-grey: #ededed;
  --ion-color-grey-rgb: 237 , 237, 237;
  --ion-color-grey-contrast: #035397;
  --ion-color-grey-contrast-rgb: 3, 83, 151;
  --ion-color-grey-shade: #ededed;
  --ion-color-grey-tint: #ededed;
  /** dark-grey **/
  --ion-color-dark-grey: #808080;
  --ion-color-dark-grey-rgb: 128, 128, 128;
  --ion-color-dark-grey-contrast: #ffffff;
  --ion-color-dark-grey-contrast-rgb: 255, 255, 255;
  --ion-color-dark-grey-shade: #808080;
  --ion-color-dark-grey-tint: #808080;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-yellow {
  --ion-color-base: var(--ion-color-warning) !important;
  --ion-color-base-rgb: var(--ion-color-yellow-rgb) !important;
  --ion-color-contrast: var(--ion-color-yellow-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-yellow-shade) !important;
  --ion-color-tint: var(--ion-color-yellow-tint) !important;
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange) !important;
  --ion-color-base-rgb: var(--ion-color-orange-rgb) !important;
  --ion-color-contrast: var(--ion-color-orange-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-orange-shade) !important;
  --ion-color-tint: var(--ion-color-orange-tint) !important;
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey) !important;
  --ion-color-base-rgb: var(--ion-color-grey-rgb) !important;
  --ion-color-contrast: var(--ion-color-grey-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-grey-shade) !important;
  --ion-color-tint: var(--ion-color-grey-tint) !important;
}

.ion-color-dark-grey {
  --ion-color-base: var(--ion-color-dark-grey) !important;
  --ion-color-base-rgb: var(--ion-color-dark-grey-rgb) !important;
  --ion-color-contrast: var(--ion-color-dark-grey-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-dark-grey-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-dark-grey-shade) !important;
  --ion-color-tint: var(--ion-color-dark-grey-tint) !important;
}

/*
 * Dark Colors
 * -------------------------------------------
 */
body.dark {
  /** primary **/
  --ion-color-primary: #035397;
  --ion-color-primary-rgb: 3, 83, 151;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: #E63137;
  --ion-color-secondary-rgb: 230, 49, 55;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #E63137;
  --ion-color-secondary-tint: #E63137;
  /** tertiary **/
  --ion-color-tertiary: #333333;
  --ion-color-tertiary-rgb: 51, 51, 51;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #333333;
  --ion-color-tertiary-tint: #333333;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #ffffff;
  --ion-color-light-tint: #ffffff;
  /** white**/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;
  /** yellow **/
  --ion-color-yellow: #ffc409;
  --ion-color-yellow-rgb: 255, 196, 9;
  --ion-color-yellow-contrast: #ffffff;
  --ion-color-yellow-contrast-rgb: 255, 255, 255;
  --ion-color-yellow-shade: #e0ac08;
  --ion-color-yellow-tint: #ffca22;
  /** orange **/
  --ion-color-orange: #ff4d00;
  --ion-color-orange-rgb: 255,77,0;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #e0ac08;
  --ion-color-orange-tint: #ff4d00;
  /** grey **/
  --ion-color-grey: #ededed;
  --ion-color-grey-rgb: 237 , 237, 237;
  --ion-color-grey-contrast: #035397;
  --ion-color-grey-contrast-rgb: 3, 83, 151;
  --ion-color-grey-shade: #ededed;
  --ion-color-grey-tint: #ededed;
  /** dark-grey **/
  --ion-color-dark-grey: #808080;
  --ion-color-dark-grey-rgb: 128, 128, 128;
  --ion-color-dark-grey-contrast: #ffffff;
  --ion-color-dark-grey-contrast-rgb: 255, 255, 255;
  --ion-color-dark-grey-shade: #808080;
  --ion-color-dark-grey-tint: #808080;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */
.ios body.dark {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;
  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
  --ion-item-background: #ffffff;
  --ion-card-background: #ffffff;
}

.ios body.dark ion-modal {
  --ion-background-color: var(--ion-color-white);
  --ion-toolbar-background: var(--ion-color-white);
  --ion-toolbar-border-color: var(--ion-color-white);
  --ion-item-background: var(--ion-color-step-white);
}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */
.md body.dark {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;
  --ion-border-color: #222222;
  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
  --ion-item-background: #ffffff;
  --ion-toolbar-background: #ffffff;
  --ion-tab-bar-background: #ffffff;
  --ion-card-background: #ffffff;
}